import { Fragment } from "react";
import "./dashboard.styles.scss";
import { Helmet } from "react-helmet-async";
import Sidebar from "components/Norwegian/sidebarNorwegian/sidebar.component";
import Footer from "components/Norwegian/footerNorwegian/footer.component";
import DashboardLayoutNorwegian from "../../components/Norwegian/dashboardLayoutNorwegian/dashboardLayout.component";
import { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import NoSiteDashboardNorwegian from "components/Norwegian/noSiteDashboard/noSiteDashboard";
import LoadingScreen from "components/Norwegian/loadingScreen/loadingScreen";

function DashboardNorwegian() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [verifiedLogOut, setVerifiedLogOut] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [selectedWebsite, setSelectedWebsite] = useState(0);
  const [jwtToken, setJwtToken] = useState("");

  function changeSelectedWebsite(e) {
    setSelectedWebsite(e.target.getAttribute("dataselect"));
  }

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      setJwtToken(accessToken);
      return;
    }
    const googleToken = localStorage.getItem("google_token");
    console.log(googleToken);
    callJwtToken(googleToken);
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      localStorage.setItem("google_token", codeResponse.access_token);
      callJwtToken(codeResponse.access_token);
    },
  });

  function callJwtToken(token) {
    console.log(token);
    fetch("https://api.exotechweb.com/authorize", {
      method: "POST",
      body: JSON.stringify({
        access_token: token,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (!data?.status) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("google_token");
          setLoggedIn(false);
          setVerifiedLogOut(true);
          window.location.href = "/login";
          return;
        }
        setJwtToken(data.access_token);
        localStorage.setItem("access_token", data.access_token);
      });
  }

  function logOut() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("google_token");
    setLoggedIn(false);
    setVerifiedLogOut(true);
    setAnalytics({});
    setJwtToken("");
    window.location.href = "/";
  }

  useEffect(() => {
    const jwtToken = localStorage.getItem("access_token");

    if (!jwtToken) {
      setVerifiedLogOut(true);
      return;
    }

    fetch("https://api.exotechweb.com/dashboard", {
      method: "POST",
      headers: { Authorization: `Bearer ${jwtToken}` },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (!data?.status) {
          localStorage.removeItem("access_token");
          setLoggedIn(false);
          setVerifiedLogOut(true);
          localStorage.removeItem("google_token");
          window.location.href = "/login";
          return;
        }

        setLoggedIn(true);
        setAnalytics(data);
      });
    setJwtToken(jwtToken);
  }, []);

  useEffect(() => {
    if (!jwtToken || loggedIn) return;

    fetch("https://api.exotechweb.com/dashboard", {
      method: "POST",
      headers: { Authorization: `Bearer ${jwtToken}` },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (!data?.status) {
          localStorage.removeItem("access_token");
          setLoggedIn(false);
          setVerifiedLogOut(true);
          localStorage.removeItem("google_token");
          window.location.href = "/login";
          return;
        }

        setAnalytics(data);
      });
  }, [loggedIn, jwtToken]);

  return (
    <Fragment>
      <Helmet>
        <title>ExoTech &mdash; Dashboard</title>
        <meta
          name='description'
          content="Explore and manage your online presence effortlessly with our intuitive dashboard designed for website renters like you. Gain valuable insights, monitor your website's performance, and stay in control of your digital space!"
        />
      </Helmet>

      <div className='app'>
        <Sidebar />
        {Object.keys(analytics)?.length ? (
          analytics.websites.length > 0 ? (
            <DashboardLayoutNorwegian
              loggedIn={loggedIn}
              allData={analytics}
              logOut={logOut}
              callJwtToken={callJwtToken}
              changeSelectedWebsite={changeSelectedWebsite}
              selectedWebsite={selectedWebsite}
              verifiedLogOut={verifiedLogOut}
            />
          ) : (
            <NoSiteDashboardNorwegian logOut={logOut} />
          )
        ) : (
          <LoadingScreen />
        )}

        <Footer />
      </div>
    </Fragment>
  );
}

export default DashboardNorwegian;
