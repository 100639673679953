import AccessButtonNorwegian from "components/Norwegian/accessButtonNorwegian/accessButtonNorwegian";
import "../pricing.styles.scss";

export default function MediumPricingCard() {
  return (
    <div className='price_card bravo pb-4'>
      <div className='header-price'>
        <span className='price'>899 kr</span>
        <span className='name'>Medium pakke</span>
      </div>
      <ul className='features'>
        <li>Opptil 5 sider</li>
        <li>Kontaktskjema</li>
        <li>Feilfri garanti </li>
        <li>Ingen bindingstid</li>
        <li>Meny og priser</li>

        <li>Rediger selv</li>
      </ul>
      <AccessButtonNorwegian
        message='Ta kontakt nå!'
        title='899 kr/mnd'
        link='/contact'
      />
    </div>
  );
}
