import "./testimonials.styles.scss";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

const Testimonials = () => {
  let testimonialsData = [
    {
      name: "Anna Skjelbred",
      company: "Psykolog Trondheim",
      content:
        "Exactly what I was looking for! I am very pleased with the stylish design and the user-friendly experience.",
    },
    {
      name: "Johan Glesnes",
      company: "Frauna",
      content:
        " The design is both attractive and functional, and the collaboration was a pleasure. Thank you for the professional effort and the wonderful end result!",
    },
    {
      name: "Zach Browning",
      company: "Prophetbots",
      content:
        "I am extremely pleased with the website ExoTech crafted for my business. Their team's creativity and technical expertise resulted in a visually stunning and user-friendly site that perfectly captured my ideas.",
    },
  ];

  const TestimonialsComponent = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const currentSlideMain = 1;
    const [slideClass, setSlideClass] = useState("");

    const handlePrevClick = () => {
      setSlideClass("left");
      setTimeout(() => {
        const [first, second, third] = testimonialsData;
        testimonialsData = [second, third, first];
        setSlideClass("");
      }, 500);

      setCurrentSlide((prevSlide) =>
        prevSlide > 0 ? prevSlide - 1 : testimonialsData.length - 1
      );
    };

    const handleNextClick = () => {
      setSlideClass("right");

      setTimeout(() => {
        const [first, second, third] = testimonialsData;
        testimonialsData = [third, first, second];
        setSlideClass("");
      }, 500);
      setTimeout(() => {}, 200);
      setCurrentSlide((prevSlide) =>
        prevSlide < testimonialsData.length - 1 ? prevSlide + 1 : 0
      );
    };

    return (
      <div className='testimonials__card-container'>
        <div className='testimonials__cards mb-bg'>
          {testimonialsData.map((testimonial, index) => (
            <div
              key={index}
              className={`testimonials__card ${
                index === currentSlide ? "" : ""
              }${index === currentSlideMain ? "active" : ""} ${
                slideClass === "right" && index < 2
                  ? "slide-right"
                  : slideClass === "right" && index === 2 && "slide-mega-left"
              } ${
                slideClass === "left" && index > 0
                  ? "slide-left"
                  : slideClass === "left" && index === 0 && "slide-mega-right"
              }`}
            >
              <h3 className='testimonials__heading'>{testimonial.name}</h3>
              <h4 className='testimonials__sub-heading mb-sm'>
                {testimonial.company}
              </h4>
              <p className='testimonials__text'>
                <FaQuoteLeft className='dashboard__quote right' />
                {testimonial.content}
                <FaQuoteRight className='dashboard__quote left' />
              </p>
            </div>
          ))}
        </div>
        <div className='testimonials__buttons'>
          <button className='testimonials__btn' onClick={handlePrevClick}>
            <FaArrowLeft className='testimonials__buttons--icon' />
          </button>
          <button className='testimonials__btn' onClick={handleNextClick}>
            <FaArrowRight className='testimonials__buttons--icon' />
          </button>
        </div>
      </div>
    );
  };

  return (
    <section className='testimonials'>
      <div className='testimonials__container'>
        <h2 className='heading-2'>What our clients have said about us</h2>
      </div>
      <TestimonialsComponent />
    </section>
  );
};

export default Testimonials;
