import "../../dashboardLayout/dashboardLayout.styles.scss";

function NoSiteDashboardNorwegian({ logOut }) {
  function codedLogOut() {
    logOut();
  }
  return (
    <div className='dashboard'>
      <div className='dashboard__header mb-md'>
        <div className='dashboard__header--left'>
          <div className='dashboard__title mb-sm'>
            Du har ingen aktiv nettside
          </div>
          <div className='dashboard__sub-text'>
            Kontakt oss nå for å bygge din nye nettside!
          </div>
        </div>
        <div className='dashboard__header--right'>
          <button onClick={codedLogOut} className='dashboard__header--btn'>
            Logg ut
          </button>
        </div>
      </div>
      <div className='dashboard__layout grid-noSite'>
        <div className='dashboard__card'></div>
        <div className='dashboard__card'></div>
        <div className='dashboard__card'></div>
        <div className='dashboard__card'></div>
      </div>
    </div>
  );
}

export default NoSiteDashboardNorwegian;
