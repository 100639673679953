import "../../header2/header2.styles.scss";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";

function Header2() {
  const dispatch = useDispatch();
  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }

  return (
    <div className="header2">
      <button
        onClick={toggleMenu}
        className="sidebar__open-close-btn sidebar__open-close-btn--active"
      >
        <FaBars className="sidebar__open-close-btn--icon" />
      </button>
      <div className="header2__text-box">
        <h2 className="heading-2 mb-ws">Kontakt oss i</h2>
        <h2 className="heading-1 mb-bg">ExoTech</h2>
        <h3 className="heading-3 heading-3--gold">
          Send melding, ring eller epost om du er interessert
        </h3>
      </div>

      <div className="header2__image-box">
        <img
          src={require("../../../img/3d-phone.png")}
          alt=""
          className="header2__img"
          draggable="false"
        />
        <img
          src={require("../../../img/3d-square.png")}
          alt=""
          className="header2__square"
          draggable="false"
        />
      </div>
    </div>
  );
}

export default Header2;
