import "../Norwegian/calculatorForm/calculatorForm.styles.scss";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";
import SmallPricingCard from "components/pricing/pricingCards/SmallCard";
import MediumPricingCard from "components/pricing/pricingCards/MediumPricingCard";
import LargePricingCard from "components/pricing/pricingCards/LargePricingCard";

const furtherQuestions = [
  {
    id: 3,
    text: "Should customers be able to pay on the website?",
    answer: "",
    radio: true,
    radioFields: ["No", "Yes"],
  },
  {
    id: 4,
    text: "Should the website show menus or prices?",
    answer: "",
    radio: true,
    radioFields: ["No", "Yes"],
  },
  {
    id: 5,
    text: "How many pages will the website have?",
    answer: "",
    radio: true,
    radioFields: ["1 Page", "Up to 5 pages", "More than 5 pages"],
  },
  {
    id: 6,
    text: "Whats your contact info?",

    quantity: 3,
    labels: [
      { type: "email", text: "Email", answer: "" },
      { type: "tel", text: "Phone number", answer: "" },
      { type: "text", text: "Full name", answer: "" },
    ],
    submit: true,
  },
];

const easyQuestions = [
  {
    id: 3,
    text: "Whats the name of your current website domain?",
    answer: "",
    quantity: 1,
    labels: [{ type: "text", text: "Domain" }],
  },
  {
    id: 4,
    text: "Why do you want a new website?",
    answer: "",
    quantity: 1,
    labels: [{ type: "text", text: "" }],
  },
  {
    id: 5,
    text: "Whats your contact info?",

    quantity: 3,
    labels: [
      { type: "email", text: "Email", answer: "" },
      { type: "tel", text: "Phone number", answer: "" },
      { type: "text", text: "Full name", answer: "" },
    ],
    submit: true,
  },
];

function CalculatorForm() {
  const [questions, setQuestions] = useState([
    {
      id: 1,
      text: "Whats your name?",
      answer: "",
      quantity: 1,
      labels: [{ type: "text", text: "First name" }],
    },
    {
      id: 2,
      text: "Do you already own a website?",
      answer: "",
      radio: true,
      radioFields: ["No", "Yes"],
    },
  ]);
  const [done, setDone] = useState(false);
  const [result, setResult] = useState("");

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleInputChange = (e) => {
    const newQuestions = [...questions];

    if (
      questions[currentQuestion].quantity &&
      questions[currentQuestion].quantity > 1
    ) {
      newQuestions[currentQuestion].labels[
        Number(e.target.getAttribute("dataselect"))
      ].answer = e.target.value;
      setQuestions(newQuestions);
      console.log(newQuestions);
      return;
    }

    newQuestions[currentQuestion].answer = e.target.value;
    setQuestions(newQuestions);
    console.log(newQuestions);
  };

  const handleNextQuestion = () => {
    if (currentQuestion === 1) {
      if (questions[1].answer === "Nei") {
        const ogQuestions = [...questions];
        const combinedList = ogQuestions.concat(furtherQuestions);
        setQuestions(combinedList);
      } else {
        const ogQuestions = [...questions];
        const combinedList = ogQuestions.concat(easyQuestions);
        setQuestions(combinedList);
      }
    }
    if (
      (currentQuestion < questions.length - 1 &&
        questions[currentQuestion].answer) ||
      questions.length === 2
    ) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  function submitForm() {
    if (questions[currentQuestion].labels.some((obj) => obj.answer === "")) {
      return;
    }
    fetch("https://api.exotechweb.com/price-calculator", {
      method: "POST",
      body: JSON.stringify({
        data: questions,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log(res);
        if (!res?.status) return;
        setDone(true);
        setResult(res.plan);
      });
  }

  const dispatch = useDispatch();
  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }

  return (
    <div className='calculator-form'>
      <button
        onClick={toggleMenu}
        className='sidebar__open-close-btn sidebar__open-close-btn--active'
      >
        <FaBars className='sidebar__open-close-btn--icon' />
      </button>
      {done ? (
        <div className='w-full grid justify-center items-center grid-1-col gap-y-24 '>
          <h2 className='heading-2'> We recommend you: </h2>
          {result === "stor" ? (
            <LargePricingCard />
          ) : result === "medium" ? (
            <MediumPricingCard />
          ) : (
            <SmallPricingCard />
          )}
        </div>
      ) : (
        <>
          {" "}
          <h2 className='heading-2 mb-sm'>
            Which type of website would you like?
          </h2>
          <h4 className='heading-4 mb-hg'>Get a price estimate in 1 minute!</h4>
          <div className='calculator-form__progress-bar-container'>
            <div
              className='calculator-form__progress-bar'
              style={{
                width: `${((currentQuestion + 1) * 100) / questions.length}%`,
              }}
            ></div>
          </div>{" "}
          <p className='calculator-form__question'>
            {questions[currentQuestion].text}
          </p>
          <div className='calculator-form__flex'>
            {/*   <button className='calculator-form__btn' onClick={handlePrevQuestion}>
          <FaArrowLeft className='calculator-form__icon' />
        </button> */}
            <div className='calculator-form__input-box'>
              {questions[currentQuestion].radio ? (
                <div className='yes-no-option'>
                  {questions[currentQuestion].radioFields.map((field, i) => {
                    return (
                      <div key={`${field}${questions[currentQuestion].id}`}>
                        <label
                          className='calculator-form__label'
                          htmlFor={`radioOption${i}`}
                        >
                          <input
                            className='calculator-form__radio'
                            type='radio'
                            id={`radioOption${i}`}
                            name='answerGroup'
                            value={field}
                            onChange={handleInputChange}
                          />
                          {field}
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  {questions[currentQuestion].labels.map((label, i) => {
                    return (
                      <label key={label.text} htmlFor={`radioOption${i}`}>
                        <input
                          type='text'
                          dataselect={i}
                          id={`radioOption${i}`}
                          value={questions[currentQuestion].answer}
                          onChange={handleInputChange}
                        />
                        {label.text}
                      </label>
                    );
                  })}
                  {questions[currentQuestion].submit && (
                    <button
                      className='calculator-form__submit'
                      onClick={submitForm}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
            </div>
            {!questions[currentQuestion].submit && (
              <button
                className='calculator-form__btn'
                onClick={handleNextQuestion}
              >
                <FaArrowRight className='calculator-form__icon' />
              </button>
            )}
          </div>
          <div></div>
        </>
      )}
    </div>
  );
}

export default CalculatorForm;
