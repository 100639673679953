import { Fragment } from "react";
import "./home.styles.scss";
import { Helmet } from "react-helmet-async";
import Sidebar from "../../components/sidebar/sidebar.component";
import Header from "../../components/header/header.component";
import About from "../../components/about/about.component";
import Services from "../../components/services/services.component";
import InfoDashboard from "components/infoDashboard/infoDashboard.component";
import Portfolio from "../../components/portfolio/portfolio.components";
import Testimonials from "../../components/testimonials/testimonials.component";
import Footer from "../../components/footer/footer.component";

function Home() {
  return (
    <Fragment>
      <Helmet>
        {/* Add Google Tag Manager script */}
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-WQ2JPD16T2'
        ></script>

        {/* Add Google Analytics configuration script */}
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-WQ2JPD16T2');
      `}
        </script>
        <title>ExoTech &mdash; Get Your New Website Now</title>
        <meta
          name='description'
          content='Do you need a new website? We in ExoTech make sure our clients get modern looking, responsive and the most user friendly websites. Start your journey with a better website now!'
        />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        ></meta>
        {/*  <link rel='canonical' href='https://exotechweb.com' /> */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content='ExoTech Web' />
        <meta
          property='og:description'
          content='Do you need a new website? We in ExoTech make sure our clients get modern looking, responsive and the most user friendly websites. Start your journey with a better website now!'
        />
        <meta
          property='og:image'
          content='https://exotechweb.com/Capture.PNG'
        />
        <meta property='og:url' content='https://exotechweb.com' />
      </Helmet>

      <div className='app'>
        <Sidebar />

        <Header />
        <About />
        <Services />
        <InfoDashboard />
        <Portfolio />
        <Testimonials />
        <Footer />
      </div>
    </Fragment>
  );
}

export default Home;
