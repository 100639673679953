import AccessButtonNorwegian from "components/Norwegian/accessButtonNorwegian/accessButtonNorwegian";
import "../../Norwegian/pricingNorwegianComponent/pricing.styles.scss";

export default function SmallPricingCard() {
  return (
    <div className='price_card alpha pb-4'>
      <div className='header-price'>
        <span className='price'>$39.9 </span>
        <span className='name'>Small package</span>
      </div>
      <ul className='features'>
        <li>1 page</li>
        <li>Contact form</li>
        <li>Flawless guarantee</li>
        <li>No contract period</li>
      </ul>
      <AccessButtonNorwegian
        message='Contact us now!'
        title='$39.9 USD monthly'
        link='/contact'
      />
    </div>
  );
}
