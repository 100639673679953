import "./infoDashboard.styles.scss";

function InfoDashboard() {
  return (
    <div className='info-dashboard'>
      <div className='info-dashboard__title-box mb-md'>
        <h2 className='heading-2 mb-sm'>
          Experience your <br /> personal Dashboard!
        </h2>
        <p className='paragraph '>
        When you have contacted us and we have identified the right package for you, all information about your website will be stored here.{" "}
          <br /> Log in with Google to access your customized dashboard, where you can manage and review your website.
        </p>
      </div>

      <div className='info-dashboard__container mb-md'>
        <div className='info-dashboard__text-box'>
          <p className='paragraph mb-sm'>
          The following key features await you:
          </p>
          <ul className='info-dashboard__list mb-md'>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Simple Google Login:
              </span>
              Use your Google account for seamless access to the dashboard.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Website-preview:
              </span>
              Get a glimpse of how your website currently appears.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Subscription Management:
              </span>
              Easy management of your subscription and the ability to upgrade services as needed
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                User statistics:
              </span>
              See the number of new visitors to your website recently.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>Visitor analysis:</span>
              Explore a graph displaying the number of visitors to your website over the last 30 days.
            </li>
          </ul>
        </div>
        <div className='info-dashboard__image-box'>
          <img
            src={require("../../img/dashboardinfo_english.png")}
            alt='dashboard bilde'
            draggable='false'
            className='info-dashboard__img'
          />
          <img
            src={require("../../img/exotech_graph.png")}
            alt='dashboard bilde'
            draggable='false'
            className='info-dashboard__img'
          />
        </div>
      </div>
      
    </div>
  );
}

export default InfoDashboard;
