import "../../sidebar/sidebar.styles.scss";
import { IonIcon } from "@ionic/react";
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { logoFacebook } from "ionicons/icons";
import { logoInstagram } from "ionicons/icons";
import { logoTwitter } from "ionicons/icons";
import { logoDiscord } from "ionicons/icons";
import { logoSnapchat } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLang, toggleMenuOpen } from "../../../store/redux/language";
import { useState, useRef, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const sidebarRef = useRef(null);
  function navLinkPressHandler(e) {
    if (!window.location.href === "/") window.location.href = "/";

    const data = e.target.getAttribute("data");

    const selectedElement = document.getElementById(data.toString());

    selectedElement.scrollIntoView({ behavior: "smooth" });
  }

  const dispatch = useDispatch();
  function setEnglish() {
    dispatch(changeLang("en"));
  }

  const [mouseHover, setMouseHover] = useState(false);

  function showLang() {
    setMouseHover(true);
  }
  function hideLang() {
    setMouseHover(false);
  }

  function dashboardLogin() {
    if (menuOpen) dispatch(toggleMenuOpen());
    const potGooTok = localStorage.getItem("google_token");
    const accTok = localStorage.getItem("access_token");

    if (potGooTok || accTok) {
      console.log(potGooTok);
      window.location.href = "/dashboard";
      return;
    }
    login();
  }
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      localStorage.setItem("google_token", codeResponse.access_token);

      navigate("/dashboard");
    },
  });

  const { menuOpen } = useSelector((state) => state.language);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth < 1000) {
        if (sidebarRef.current) {
          sidebarRef.current.style.display = menuOpen ? "flex" : "none";
        }
      } else {
        if (sidebarRef.current) {
          sidebarRef.current.style.display = "flex";
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuOpen]);

  function closeMenu() {
    dispatch(toggleMenuOpen());
  }

  function toggleIfOpen() {
    if (menuOpen) dispatch(toggleMenuOpen());
  }

  return (
    <div class='sidebar' ref={sidebarRef}>
      <button className='sidebar__open-close-btn'>
        <FaBars className='sidebar__open-close-btn--icon' />
      </button>

      <button onClick={closeMenu} className='sidebar__open-close-btn'>
        <RxCross2 className='sidebar__open-close-btn--icon ' />
      </button>
      <div class='sidebar__content'>
        <div class='sidebar__heading'>
          <Link onClick={toggleIfOpen} to='/' className='flex justify-center'>
            <img
              src={require("../../../img/Exotechweb_413_x_190_px_2.png")}
              alt=''
              className='sidebar__logo'
            />
          </Link>
        </div>

        <div className='sidebar__language'>
          <div
            className='sidebar__default-lang sidebar__selectedNor'
            onMouseEnter={showLang}
            onMouseLeave={hideLang}
          >
            Norsk
          </div>
          <ul
            className={`sidebar__flag-list ${!mouseHover && "hidden"}`}
            onMouseEnter={showLang}
            onMouseLeave={hideLang}
          >
            <li className='sidebar__flag-item'>
              <div className='sidebar__language-switcher '>
                <img
                  className='flag-img'
                  alt='changetonorwegian'
                  src={require("../../../img/norwayround.png")}
                />
                <p>Norsk</p>
              </div>
            </li>
            <li className='sidebar__flag-item' onClick={setEnglish}>
              <div className='sidebar__language-switcher '>
                <img
                  className='flag-img'
                  alt='changetoenglish'
                  src={require("../../../img/ukround.png")}
                />
                <p>English</p>
              </div>
            </li>
          </ul>
        </div>

        <ul class='sidebar__nav'>
          <li class='sidebar__nav--item' onClick={navLinkPressHandler}>
            <Link
              onClick={toggleIfOpen}
              to='/'
              class='sidebar__nav--link'
              data='1'
            >
              Om oss
            </Link>
          </li>
          <li class='sidebar__nav--item' onClick={navLinkPressHandler}>
            <Link
              onClick={toggleIfOpen}
              to='/'
              data='2'
              class='sidebar__nav--link'
            >
              Tjenester
            </Link>
          </li>
          <li class='sidebar__nav--item' onClick={navLinkPressHandler}>
            <Link
              onClick={toggleIfOpen}
              to='/'
              class='sidebar__nav--link'
              data='3'
            >
              Portefølje
            </Link>
          </li>
          <li class='sidebar__nav--item'>
            <Link
              onClick={toggleIfOpen}
              to='/contact'
              class='sidebar__nav--link'
            >
              Kontakt oss
            </Link>
          </li>

          {/* <li class='sidebar__nav--item'>
            <Link
              onClick={toggleIfOpen}
              to='/calculator'
              class='sidebar__nav--link'
            >
              Priskalkulator
            </Link>
          </li> */}
          <li class='sidebar__nav--item'>
            <Link onClick={toggleIfOpen} to='/info' class='sidebar__nav--link'>
              Info
            </Link>
          </li>
          {/*  <li class='sidebar__nav--item'>
            <Link
              onClick={toggleIfOpen}
              to='/pricing'
              class='sidebar__nav--link'
            >
              Priser
            </Link>
          </li> */}
        </ul>
        <Link onClick={dashboardLogin} className='sidebar__btn'>
          Min nettside
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
