import AccessButtonNorwegian from "components/Norwegian/accessButtonNorwegian/accessButtonNorwegian";
import "../../Norwegian/pricingNorwegianComponent/pricing.styles.scss";

export default function MediumPricingCard() {
  return (
    <div className='price_card bravo pb-4'>
      <div className='header-price'>
        <span className='price'>$89.9 </span>
        <span className='name'>Medium package</span>
      </div>
      <ul className='features'>
        <li>Up to 5 pages</li>
        <li>Contact form</li>
        <li>Flawless guarantee </li>
        <li>No contract period</li>
        <li>Meny og priser</li>

        <li>Edit yourself</li>
      </ul>
      <AccessButtonNorwegian
        message='Contact us now!'
        title='$89.9 USD monthly'
        link='/contact'
      />
    </div>
  );
}
