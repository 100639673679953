import { useNavigate } from "react-router-dom";
import "./loginPage.styles.scss";
import { useGoogleLogin } from "@react-oauth/google";

function LoginPage() {
  function dashboardLogin() {
    const potGooTok = localStorage.getItem("google_token");
    const accTok = localStorage.getItem("access_token");

    if (potGooTok || accTok) {
      console.log(potGooTok);
      window.location.href = "/dashboard";
      return;
    }
    login();
  }
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      localStorage.setItem("google_token", codeResponse.access_token);

      navigate("/dashboard");
    },
  });

  return (
    <div className='login-page'>
      <div className='login-page__container'>
        <h2 className='heading-2'>Sign in to continue</h2>
        <button onClick={dashboardLogin} className='login-page__btn'>
          Sign in
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
