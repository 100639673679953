import "./pricingHeader.styles.scss";

function PricingHeader() {
  return (
    <div className='pricing-header'>
      <h2 className='heading-2'>Our pricing</h2>
    </div>
  );
}

export default PricingHeader;
