import './portfolio.styles.scss'

function Portfolio() {
  return (
    <section class='portfolio' id='3'>
      <div class='portfolio__text-box'>
        <h2 class='heading-2 mb-sm'>Portfolio</h2>
        <p class='portfolio__description mb-md'>Click to open site</p>
      </div>
      <div class='portfolio__websites hide'>
        <div class='portfolio__website-1 left-hide first-rank'>
          <a
            href='https://trondheimpsykolog.no/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={require('../../img/website-4.png')}
              alt=''
              class='portfolio__img'
            />
          </a>
          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>Psykolog Trondheim</h4>
            <p className='portfolio__paragraph'>
              We made this website for a private therapy company. This website
              contains a feature allowing the user to send an email by filling
              out a form. The website palette is designed around the clients
              logo and personal preference. It also includes SEO work resulting
              in a boost of google searches and google site ranking.
            </p>
          </div>
        </div>
        <div class='portfolio__website-1 right-hide first-rank'>
          <a href='https://prophetbots.com' target='_blank' rel='noreferrer'>
            <img
              src={require('../../img/website-13.png')}
              alt=''
              class='portfolio__img'
            />
          </a>
          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>ProphetBots</h4>
            <p className='portfolio__paragraph'>
              This is the website for a crypto project we have been part of. We
              were tasked with creating their website and this is what we came
              up with. Its a multi-page website with lots of advanced animations
              and designs.{' '}
            </p>
          </div>
        </div>
        <div class='portfolio__website-1 left-hide second-rank'>
          <a href='https://frauna.no/' target='_blank' rel='noreferrer'>
            <img
              src={require('../../img/website-14.png')}
              alt=''
              class='portfolio__img'
            />
          </a>

          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>Frauna Restaurant</h4>
            <p className='portfolio__paragraph'>
              This is a website we made for a restaurant in Norway. The website
              contains a booking system and user friendly interface.
            </p>
          </div>
        </div>
        <div class='portfolio__website-1 right-hide second-rank'>
          <a href='https://www.ovre-as.no/' target='_blank' rel='noreferrer'>
            <img
              src={require('../../img/website-15.png')}
              alt=''
              class='portfolio__img'
            />
          </a>
          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>Øvre Drift og Vedlikehold</h4>
            <p className='portfolio__paragraph'>
              This is a website requested by a business in Norway. The website
              focuses on pointing out the selling points for the business whilst
              also making it easy for the customer to reach out.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
