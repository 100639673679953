import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "en",
  accessToken: "",
  menuOpen: false,
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLang: (state, action) => {
      const { payload } = action;
      state.lang = payload;
    },
    setAccessToken: (state, action) => {
      const { payload } = action;
      state.accessToken = payload;
    },
    toggleMenuOpen: (state) => {
      state.menuOpen = !state.menuOpen;
      console.log(state.menuOpen);
    },
  },
});

export const changeLang = languageSlice.actions.changeLang;
export const setAccessToken = languageSlice.actions.setAccessToken;
export const toggleMenuOpen = languageSlice.actions.toggleMenuOpen;

export default languageSlice.reducer;
