import { Fragment } from "react";
import "../home/home.styles.scss";
import { Helmet } from "react-helmet-async";
import Sidebar from "../../components/Norwegian/sidebarNorwegian/sidebar.component";
import Header from "../../components/Norwegian/headerNorwegian/header.component";
import About from "../../components/Norwegian/aboutNorwegian/about.component";
import Services from "../../components/Norwegian/servicesNorwegian/services.component";
import InfoDashboardNorwegian from "components/Norwegian/infoDashboardNorwegian/infoDashboard.component";
import Portfolio from "../../components/Norwegian/portfolioNorwegian/portfolio.components";
import Testimonials from "../../components/Norwegian/testimonialsNorwegian/testimonials.component";
import Footer from "../../components/Norwegian/footerNorwegian/footer.component";

function HomeNorwegian() {
  return (
    <Fragment>
      <Helmet>
        <title>ExoTech &mdash; Bestill drømmesiden din nå!</title>
        <meta
          name='description'
          content='Do you need a new website? We in ExoTech make sure our clients get modern looking, responsive and the most user friendly websites. Start your journey with a better website now!'
        />
        {/*  <link rel='canonical' href='https://exotechweb.com' /> */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content='ExoTech Web' />
        <meta
          property='og:description'
          content='Do you need a new website? We in ExoTech make sure our clients get modern looking, responsive and the most user friendly websites. Start your journey with a better website now!'
        />
        <meta
          property='og:image'
          content='https://exotechweb.com/Capture.PNG'
        />
        <meta property='og:url' content='https://exotechweb.com' />
      </Helmet>

      <div className='app'>
        <Sidebar />

        <Header />

        <About />

        <Services />

        <InfoDashboardNorwegian />

        <Portfolio />

        <Testimonials />

        <Footer />
      </div>
    </Fragment>
  );
}

export default HomeNorwegian;
