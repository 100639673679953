import AccessButtonNorwegian from "components/Norwegian/accessButtonNorwegian/accessButtonNorwegian";
import "../../Norwegian/pricingNorwegianComponent/pricing.styles.scss";

export default function LargePricingCard() {
  return (
    <div className='price_card charlie pb-4'>
      <div className='header-price'>
        <span className='price'>$149.9 </span>
        <span className='name'>Big package</span>
      </div>
      <ul className='features'>
        <li>Up to 10 pages</li>
        <li>Contact form</li>
        <li>Flawless guarantee</li>
        <li>No contract period</li>
        <li>Menu and prices</li>

        <li>Edit yourself</li>
        <li>Blog / News</li>
        <li>Payment solution</li>
        <li>Integrated chatbot</li>
      </ul>
      <AccessButtonNorwegian
        message='Contact us now!'
        title='$149.9 USD monthly'
        link='/contact'
      />
    </div>
  );
}
