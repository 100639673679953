import "../../header/header.styles.scss";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";

function Header() {
  const dispatch = useDispatch();
  function navLinkPressHandler(e) {
    const data = e.target.getAttribute("data");

    const selectedElement = document.getElementById(data.toString());

    selectedElement.scrollIntoView({ behavior: "smooth" });
  }

  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }

  return (
    <div class='header' id='4'>
      <button
        onClick={toggleMenu}
        className='sidebar__open-close-btn sidebar__open-close-btn--active'
      >
        <FaBars className='sidebar__open-close-btn--icon' />
      </button>
      <img
        src={require("../../../img/Exotechweb_413_x_190_px_2.png")}
        alt='logo'
        class='header__logo'
        draggable='false'
      />
      <h1 class='heading-1 heading-1--gold'>Norge</h1>
      <h3 class='heading-3 heading-3--gold'>
        Nettsider levert enkelt og kontinuerlig
      </h3>

      <Link to='/contact' class='btn header__btn'>
        Kom i gang nå
      </Link>
      <Link
        onClick={navLinkPressHandler}
        data='1'
        class='header__link header__btn'
      >
        Les mer &rarr;
      </Link>
    </div>
  );
}

export default Header;
