import { Link } from "react-router-dom";
import "./info.styles.scss";

import { useRef } from "react";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";

function InfoPage() {
  const dispatch = useDispatch();
  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }
  return (
    <>
      <section class="info-page-2 hide" id="1">
        <button
          onClick={toggleMenu}
          className="sidebar__open-close-btn sidebar__open-close-btn--active"
        >
          <FaBars className="sidebar__open-close-btn--icon" />
        </button>
        <div class="info-page__text-box hide  right-hide">
          <h2 class="heading-2 mb-ws">Subscription</h2>
          <br />

          <h2 class="heading-2 mb-ws">1. Commitment</h2>
          <p class="paragraph  mb-bg">
          The subscription for website services is commitment-free. The customer is not tied to a minimum subscription and can terminate the service at any time.
          </p>

          <h2 class="heading-2 mb-ws">2. Termination</h2>
          <p class="paragraph  mb-bg">
          The customer can cancel the subscription at any time by logging into the dashboard and using the subscription termination functionality. The cancellation will take effect immediately or at the end of the current billing cycle, depending on the agreed terms.
          </p>

          <h2 class="heading-2 mb-ws">3. Change of subscription</h2>
          <p class="paragraph  mb-bg">
          Changes in subscription level or billing information can be made by the customer through the dashboard or by contacting us directly. Changes to the subscription typically take effect from the next billing period.
          </p>
          <p class="paragraph  mb-bg">
          Please note that any changes to the subscription may affect the availability of services and features.
          </p>
          <p class="paragraph  mb-bg">
          By using our subscription system, you confirm that you have read, understood, and accepted the subscription terms, including the termination conditions and the possibility of changes to the subscription.
          </p>
        </div>
      </section>
      <section class="info-page hide" id="1">
        <div class="info-page__text-box hide  right-hide">
          <h2 class="heading-2 mb-ws">Sales terms</h2>
          <p class="paragraph  mb-bg">
          These sales terms govern the agreement between Exotechweb and the customer for the provision of website services.
          </p>

          <h2 class="heading-2 mb-ws">1. Parties</h2>
          <p class="paragraph  mb-bg">
          These sales terms govern the agreement between the provider and the customer for the provision of website services.
          </p>

          <h2 class="heading-2 mb-ws">2. Payment</h2>
          <p class="paragraph  mb-bg">
          Payment for the website services is processed through Stripe or Vipps. The customer commits to paying the fixed monthly fee agreed upon upon subscription creation. All payments shall be made according to the specified payment terms.
          </p>

          <h2 class="heading-2 mb-ws">3. Delivery</h2>
          <p class="paragraph  mb-bg">
          The website services will be accessible through the specified dashboard as soon as the payment is confirmed. There is no physical delivery involved as the service is a web-based solution.
          </p>

          <h2 class="heading-2 mb-ws">4. Right of withdrawal</h2>
          <p class="paragraph  mb-bg">
          After payment, no right of withdrawal is applicable. Nevertheless, customers reserve the option to terminate the subscription at any point in time.
          </p>

          <h2 class="heading-2 mb-ws">5. Returns</h2>
          <p class="paragraph  mb-bg">
          Due to the nature of the web-based service, there is no possibility for returns of website services.
          </p>

          <h2 class="heading-2 mb-ws">6. handling of claims.</h2>
          <p class="paragraph  mb-bg">
          Any claims or issues regarding the website services should be reported to us via contact@exotechweb.com. We will handle claims fairly and promptly, aiming to resolve any issues within a reasonable timeframe.
          </p>

          <h2 class="heading-2 mb-ws">7. Conflict Resolution</h2>
          <p class="paragraph  mb-bg">
          Any disputes arising in connection with these terms and conditions or the website services shall be attempted to be resolved through negotiations between the parties. If negotiations fail to reach a solution, the dispute shall be settled in accordance with Norwegian law.
          </p>

          <p class="paragraph  mb-bg">
          By using our website services, you agree to and are bound by these terms and conditions.
          </p>
          <p class="paragraph  mb-bg">
          These terms and conditions may be modified from time to time, and any changes will be posted on our website.
          </p>
          <p class="paragraph  mb-bg">
          Please contact us if you have any questions or need further information.
          </p>
        </div>
      </section>
    </>
  );
}

export default InfoPage;
