import { Fragment } from "react";
import "../contact/contact.styles.scss";
import { Helmet } from "react-helmet-async";
import Sidebar from "components/sidebar/sidebar.component";
import PricingHeader from "components/pricingHeader/pricingHeader.component";
import PricingComponent from "components/pricing/pricing.component";
import Footer from "components/footer/footer.component";

function Pricing() {
  return (
    <Fragment>
      <Helmet>
        <title>ExoTech &mdash; Kontakt oss nå</title>
        <meta
          name='description'
          content='Get in touch with us at ExoTech! Our contact page provides all the information you need to reach out to our team. You can reach out to us by finding our phone number or our email address. We are always happy to help with any questions or concerns you may have. Contact us today!'
        />
        {/* <link rel='canonical' href='/https://exotechweb.com' /> */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content='ExoTech Web' />
        <meta
          property='og:description'
          content='Do you need a new website? We in ExoTech make sure our clients get modern looking, responsive and the most user friendly websites. Start your journey with a better website now!'
        />
        <meta
          property='og:image'
          content='https://exotechweb.com/Capture.PNG'
        />
        <meta property='og:url' content='https://exotechweb.com' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
      </Helmet>

      <div className='pricing-layout'>
        <Sidebar />

        <PricingHeader />

        <PricingComponent />

        <Footer />
      </div>
    </Fragment>
  );
}

export default Pricing;
