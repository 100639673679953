import "../../info/info.styles.scss";
import { Link } from "react-router-dom";

import { useRef } from "react";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";

function InfoPageNorwegian() {
  const dispatch = useDispatch();
  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }
  return (
    <>
      <section class="info-page-2 hide" id="1">
        <button
          onClick={toggleMenu}
          className="sidebar__open-close-btn sidebar__open-close-btn--active"
        >
          <FaBars className="sidebar__open-close-btn--icon" />
        </button>
        <div class="info-page__text-box hide  right-hide">
          <h2 class="heading-2 mb-ws">Abonnement</h2>
          <br />

          <h2 class="heading-2 mb-ws">1. Bindingstid</h2>
          <p class="paragraph  mb-bg">
            Abonnementet på nettsidetjenestene er uten bindingstid. Kunden er
            ikke bundet av et minimumsabonnement og kan avslutte tjenesten når
            som helst.
          </p>

          <h2 class="heading-2 mb-ws">2. Oppsigelse</h2>
          <p class="paragraph  mb-bg">
            Kunden kan si opp abonnementet når som helst ved å logge inn på
            dashboardet og bruke funksjonaliteten for avslutning av
            abonnementet. Oppsigelsen trer i kraft umiddelbart eller ved utløp
            av gjeldende faktureringsperiode, avhengig av avtalte vilkår.
          </p>

          <h2 class="heading-2 mb-ws">3. Endring av abonnement</h2>
          <p class="paragraph  mb-bg">
            Endringer i abonnementsnivå eller faktureringsinformasjon kan
            utføres av kunden gjennom dashboardet eller ved å kontakte oss
            direkte. Endringer i abonnementet trer vanligvis i kraft fra neste
            faktureringsperiode.
          </p>
          <p class="paragraph  mb-bg">
            Vennligst merk at eventuelle endringer i abonnementet kan påvirke
            tilgjengeligheten av tjenester og funksjoner.
          </p>
          <p class="paragraph  mb-bg">
            Ved å benytte deg av vårt abonnementssystem, bekrefter du at du har
            lest, forstått og akseptert vilkårene for abonnementet, inkludert
            oppsigelsesvilkårene og muligheten for endringer i abonnementet.
          </p>
        </div>
      </section>
      <section class="info-page hide" id="1">
        <div class="info-page__text-box hide  right-hide">
          <h2 class="heading-2 mb-ws">Salgsvilkår</h2>
          <p class="paragraph  mb-bg">
            Disse salgsvilkårene regulerer avtalen mellom exotechweb
            og kunden for levering av nettsidetjenester.
          </p>

          <h2 class="heading-2 mb-ws">1. Parter</h2>
          <p class="paragraph  mb-bg">
            Disse salgsvilkårene regulerer avtalen mellom leverandøren og kunden
            for levering av nettsidetjenester.
          </p>

          <h2 class="heading-2 mb-ws">2. Betaling</h2>
          <p class="paragraph  mb-bg">
            Betaling for nettsidetjenestene utføres gjennom Stripe eller Vipps.
            Kunden forplikter seg til å betale den faste månedsprisen som er
            avtalt ved opprettelsen av abonnementet. Alle betalinger skal gjøres
            i henhold til de angitte betalingsbetingelsene.
          </p>

          <h2 class="heading-2 mb-ws">3. Levering</h2>
          <p class="paragraph  mb-bg">
            Nettsidetjenestene vil være tilgjengelige via det angitte
            dashboardet så snart betalingen er bekreftet. Det er ingen fysisk
            levering siden tjenesten er en webbasert løsning.
          </p>

          <h2 class="heading-2 mb-ws">4. Angrerett</h2>
          <p class="paragraph  mb-bg">
            Betaling for nettsidetjenestene utføres gjennom Stripe eller Vipps.
            Kunden forplikter seg til å betale den faste månedsprisen som er
            avtalt ved opprettelsen av abonnementet. Alle betalinger skal gjøres
            i henhold til de angitte betalingsbetingelsene.
          </p>

          <h2 class="heading-2 mb-ws">5. Retur</h2>
          <p class="paragraph  mb-bg">
            Grunnet arten av tjenesten webbasert løsning, er det ingen
            mulighet for retur av nettsidetjenester.
          </p>

          <h2 class="heading-2 mb-ws">6. Reklamesjonshåndtering</h2>
          <p class="paragraph  mb-bg">
            Eventuelle reklamasjoner eller problemer med nettsidetjenestene skal
            meldes til oss via contact@exotechweb.com. Vi vil behandle
            reklamasjoner på en rettferdig og rask måte, og vil søke å løse
            eventuelle problemer innen rimelig tid.
          </p>

          <h2 class="heading-2 mb-ws">7. Konfliktløsning</h2>
          <p class="paragraph  mb-bg">
            Eventuelle tvister som oppstår i forbindelse med disse
            salgsvilkårene eller nettsidetjenestene, skal forsøkes løst gjennom
            forhandlinger mellom partene. Dersom forhandlingene ikke fører til
            en løsning, skal tvisten avgjøres i henhold til norsk lov ved.
          </p>

          <p class="paragraph  mb-bg">
            Ved å bruke våre nettsidetjenester, godtar du og forplikter deg til
            å følge disse salgsvilkårene.
          </p>
          <p class="paragraph  mb-bg">
            Disse salgsvilkårene kan endres fra tid til annen, og eventuelle
            endringer vil bli publisert på nettsiden vår.
          </p>
          <p class="paragraph  mb-bg">
            Vennligst kontakt oss hvis du har spørsmål eller trenger ytterligere
            informasjon.
          </p>
        </div>
      </section>
    </>
  );
}

export default InfoPageNorwegian;
