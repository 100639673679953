import AccessButtonNorwegian from "components/Norwegian/accessButtonNorwegian/accessButtonNorwegian";
import "../pricing.styles.scss";

export default function SmallPricingCard() {
  return (
    <div className='price_card alpha pb-4'>
      <div className='header-price'>
        <span className='price'>399 kr</span>
        <span className='name'>Liten pakke</span>
      </div>
      <ul className='features'>
        <li>1 side</li>
        <li>Kontaktskjema</li>
        <li>Feilfri garanti</li>
        <li>Ingen bindingstid</li>
      </ul>
      <AccessButtonNorwegian
        message='Ta kontakt nå!'
        title='399 kr/mnd'
        link='/contact'
      />
    </div>
  );
}
