export default function AccessButtonNorwegian(props) {
  return (
    <div className="flex flex-col items-center  space-y-4 ">
      {props.title.length > 0 && (
        <h2 className="text-2xl text-black font-bold">{props.title}</h2>
      )}
      <a href={props.link}>
        <button
          type="button"
          className="pricing-btn w-80 group/button relative flex shrink-0 items-center justify-center overflow-hidden rounded-md focus-visible:border-whop-field-highlight focus-visible:ring-whop-field-highlight/30 outline-none transition focus:outline-none focus-visible:border focus-visible:ring w-full bg-whop-primary text-whop-fixed-white text-button2 h-12 px-16 "
        >
          <div className="absolute inset-0 transition group-hover/button:bg-black/[12%] group-active/button:bg-black/[18%]"></div>
          <div className="z-10 flex items-center justify-center">
            <div className="">{props.message}</div>
          </div>
        </button>
      </a>
    </div>
  );
}
