import "../../infoDashboard/infoDashboard.styles.scss";

function InfoDashboardNorwegian() {
  return (
    <div className='info-dashboard'>
      <div className='info-dashboard__title-box mb-md'>
        <h2 className='heading-2 mb-sm'>
          Velkommen til ditt <br /> personlige Dashboard!
        </h2>
        <p className='paragraph '>
          Når du har kontaktet oss og vi har kommer fram til den riktige pakken
          for deg, vil all informasjon om din nettside bli nøye registrert her.{" "}
          <br /> Logg inn med Google for å få tilgang til ditt skreddersydde
          dashbord, hvor du kan administrere og gjennomgå nettsiden din.
        </p>
      </div>

      <div className='info-dashboard__container mb-md'>
        <div className='info-dashboard__text-box'>
          <p className='paragraph mb-sm'>
            Følgende nøkkelfunksjoner venter på deg:
          </p>
          <ul className='info-dashboard__list mb-md'>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Enkel Google-pålogging:
              </span>
              Bruk din Google-konto for problemfri tilgang til dashbordet.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Nettside-forhåndsvisning:
              </span>
              Få et glimt av hvordan nettsiden din fremstår for øyeblikket.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Abonnementsadministrasjon:
              </span>
              Enkel administrasjon av ditt abonnement og evnen til å oppgradere
              tjenester etter behov.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>
                Brukerstatistikk:
              </span>
              Se antall nye besøkende på nettsiden din nylig.
            </li>
            <li className='paragraph'>
              <span className='info-dashboard__list-title'>Besøksanalyse:</span>
              Utforsk en graf som viser antall besøkende på nettsiden din de
              siste 30 dagene.
            </li>
          </ul>
        </div>
        <div className='info-dashboard__image-box'>
          <img
            src={require("../../../img/dashboardinfo_norsk.png")}
            alt='dashboard bilde'
            draggable='false'
            className='info-dashboard__img'
          />
          <img
            src={require("../../../img/exotech_graph.png")}
            alt='dashboard bilde'
            draggable='false'
            className='info-dashboard__img'
          />
        </div>
      </div>
      <p className='paragraph '>
        Gå gjerne gjennom og få et dypt innblikk i hvordan nettsiden din
        fungerer, samtidig som du enkelt administrerer abonnementet ditt.
      </p>
    </div>
  );
}

export default InfoDashboardNorwegian;
