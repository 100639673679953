import "./loadingScreen.styles.scss";
export default function LoadingScreen() {
  return (
    <div className='loading-screen-container'>
      <div className='dashboard__loading-screen'>
        <div className='dashboard__loading-screen--loader'></div>
      </div>
    </div>
  );
}
