import "../../pricingHeader/pricingHeader.styles.scss";

function pricingHeaderNorwegian() {
  return (
    <div className="pricing-header">
      <h2 className="heading-2">Våre priser</h2>
    </div>
  );
}

export default pricingHeaderNorwegian;
