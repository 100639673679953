import AccessButtonNorwegian from "components/Norwegian/accessButtonNorwegian/accessButtonNorwegian";
import "../pricing.styles.scss";

export default function LargePricingCard() {
  return (
    <div className='price_card charlie pb-4'>
      <div className='header-price'>
        <span className='price'>1499 kr</span>
        <span className='name'>Stor pakke</span>
      </div>
      <ul className='features'>
        <li>Opptil 10 sider</li>
        <li>Kontaktskjema</li>
        <li>Feilfri garanti</li>
        <li>Ingen bindingstid</li>
        <li>Meny og prisliste</li>

        <li>Rediger selv</li>
        <li>Blogg / Nyheter</li>
        <li>Betalingsløsning</li>
        <li>Integrert chatbot</li>
      </ul>
      <AccessButtonNorwegian
        message='Ta kontakt nå!'
        title='1499 kr/mnd'
        link='/contact'
      />
    </div>
  );
}
