import '../../portfolio/portfolio.styles.scss'

function Portfolio() {
  return (
    <section class='portfolio' id='3'>
      <div class='portfolio__text-box'>
        <h2 class='heading-2 mb-sm'>Portefølje</h2>
        <p class='portfolio__description mb-md'>Klikk for å åpne hele siden</p>
      </div>
      <div class='portfolio__websites hide'>
        <div class='portfolio__website-1 left-hide first-rank'>
          <a
            href='https://trondheimpsykolog.no/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={require('../../../img/website-4.png')}
              alt=''
              class='portfolio__img'
            />
          </a>
          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>Psykolog Trondheim</h4>
            <p className='portfolio__paragraph'>
              Vi lagde denne nettsiden for en privat psykolog. Denne nettsiden
              har tjenester som f.eks lar kunden fylle inn et skjema for å sende
              en epost til eieren. Palettet brukt på nettsiden er bygd klientens
              logo og personlig preferanse. Den inneholder også SEO arbeid som
              gjør at nettsiden rangerer høyere på Google og får et høyere
              antall søkere.
            </p>
          </div>
        </div>
        <div class='portfolio__website-1 right-hide first-rank'>
          <a href='https://prophetbots.com' target='_blank' rel='noreferrer'>
            <img
              src={require('../../../img/website-13.png')}
              alt=''
              class='portfolio__img'
            />
          </a>
          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>ProphetBots</h4>
            <p className='portfolio__paragraph'>
              Dette er en nettside vi har lagd som del av et kryptoprosjekt.
              Nettsiden inneholder flere sider med mye funksjonalitet. Designet
              er avansert og styrket av dynamiske animasjoner.
            </p>
          </div>
        </div>
        <div class='portfolio__website-1 left-hide second-rank'>
          <a href='https://frauna.no/' target='_blank' rel='noreferrer'>
            <img
              src={require('../../../img/website-14.png')}
              alt=''
              class='portfolio__img'
            />
          </a>

          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>Frauna Restaurant</h4>
            <p className='portfolio__paragraph'>
              Denne siden lagde vi etter forespørsel fra restauranten Frauna.
              Siden inneholder et bookingsystem og et design egnet for
              brukervennlighet.
            </p>
          </div>
        </div>

        <div class='portfolio__website-1 right-hide second-rank'>
          <a href='https://www.ovre-as.no/' target='_blank' rel='noreferrer'>
            <img
              src={require('../../../img/website-15.png')}
              alt=''
              class='portfolio__img'
            />
          </a>
          <div className='portfolio__website-description'>
            <h4 className='heading-4 mb-ws'>Øvre Drift og Vedlikehold</h4>
            <p className='portfolio__paragraph'>
              Denne siden lagde vi etter forespørsel fra Øvre Drift og
              Vedlikehold. Siden er designet for å få frem bedriftens tjenester
              så klart som mulig, samtidig som den gjør det lett for kunden å ta
              kontakt.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
