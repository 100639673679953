import "./info.styles.scss";
import { IonIcon } from "@ionic/react";
import { callOutline } from "ionicons/icons";
import { mailOutline } from "ionicons/icons";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

function Info() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_KEY,
        process.env.REACT_APP_EMAILSJS_TEMPLATE,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(() => (window.location.href = "https://exotechweb.com/contact"));
  };

  return (
    <div className="info">
      <h2 className="heading-2 mb-sm">Let's Start a Conversation</h2>
      <h4 className="heading-4 mb-bg">
        Here are a few ways to get in touch with us!
      </h4>

      <div className="info__griddy">
        <div className="info__contact-info">
          <div className="info__phone">
            <IonIcon icon={callOutline} className="info__icon" />
            <div className="info__text-box">
              <p className="heading-4">Phone</p>
              <p className="info__text">+47 405 20 665</p>
            </div>
          </div>
          <div className="info__phone">
            <IonIcon icon={mailOutline} className="info__icon" />
            <div className="info__text-box">
              <p className="heading-4">Email</p>
              <p className="info__text">contact@exotechweb.com</p>
            </div>
          </div>
        </div>

        <div className="info__form">
          <form ref={form} onSubmit={sendEmail}>
            <h2 className="info__form-heading mb-sm">Send Message</h2>
            <div className="info__input-box">
              <input type="text" name="user_name" required="required" />
              <span className="info__form-text">Full name</span>
            </div>
            <div className="info__input-box">
              <input type="text" name="user_email" required="required" />
              <span className="info__form-text">Email</span>
            </div>
            <div className="info__input-box">
              <textarea name="message" required="required"></textarea>
              <span className="info__form-text">Type your message...</span>
            </div>
            <div className="info__input-box">
              <input type="submit" value="Send" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Info;
