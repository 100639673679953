import "./pricing.styles.scss";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";
import LargePricingCard from "./pricingCards/LargePricingCard";
import SmallPricingCard from "./pricingCards/SmallCard";
import MediumPricingCard from "./pricingCards/MediumPricingCard";

function PricingNorwegianComponent() {
  const dispatch = useDispatch();
  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }
  return (
    <div className='p-8  pricing'>
      <button
        onClick={toggleMenu}
        className='sidebar__open-close-btn sidebar__open-close-btn--active'
      >
        <FaBars className='sidebar__open-close-btn--icon' />
      </button>

      <div id='pricing' className='pricing__cards'>
        <SmallPricingCard />
        <MediumPricingCard />
        <LargePricingCard />
      </div>
    </div>
  );
}

export default PricingNorwegianComponent;
