import "./dashboardLayout.styles.scss";

import { GoBrowser } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";

import { CiCreditCard2 } from "react-icons/ci";
import { GoPeople } from "react-icons/go";
import { BsActivity } from "react-icons/bs";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "store/redux/language";
import { FaBars } from "react-icons/fa";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

function DashboardLayout({
  loggedIn,
  allData,
  logOut,
  callJwtToken,
  changeSelectedWebsite,
  selectedWebsite,
  verifiedLogOut,
}) {
  const [webData, setWebData] = useState({});
  const [curData, setCurData] = useState({});
  const [websiteToggler, setWebsiteToggler] = useState(false);

  function toggleWebsiteToggler() {
    setWebsiteToggler(!websiteToggler);
  }

  function codedLogOut() {
    setWebData({});
    logOut();
  }

  function codedChangeSelectedWebsite(e) {
    toggleWebsiteToggler();
    changeSelectedWebsite(e);
  }

  async function paySubcription() {
    console.log(allData.email);
    const response = await fetch(
      "https://api.exotechweb.com/create-checkout-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: allData.email,
          url: curData.link,
        }),
      }
    );
    const data = await response.json();
    console.log(data);
    window.location.href = data.url;
  }

  useEffect(() => {
    if (!Object.keys(allData)?.length) return;
    setCurData(allData.websites[selectedWebsite]);
    const { analytics } = allData.websites[selectedWebsite];
    console.log(analytics);
    const dataset = analytics?.visitors_last_30_days?.Dates.map(
      (_, i) => analytics?.visitors_last_30_days.Visitors[i]
    );
    const labels = analytics?.visitors_last_30_days.Dates;
    const data = {
      labels,
      datasets: [
        {
          label: "Daily visits",
          data: dataset,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    setWebData(data);
  }, [curData, allData, selectedWebsite]);

  const dispatch = useDispatch();
  function toggleMenu() {
    dispatch(toggleMenuOpen());
  }

  return (
    <div className='dashboard'>
      <button
        onClick={toggleMenu}
        className='sidebar__open-close-btn sidebar__open-close-btn--active'
      >
        <FaBars className='sidebar__open-close-btn--icon' />
      </button>
      <div className='dashboard__header'>
        <div className='dashboard__header--left'>
          {Object.keys(allData)?.length > 0 && (
            <div className='dashboard__title mb-sm'>
              Welcome back <br />
              <span className='dashboard__client-name'>
                {allData?.username}!
              </span>
            </div>
          )}
          {Object.keys(webData)?.length > 0 && (
            <div className='dashboard__sub-text mb-bg'>
              Manage and monitor your website.
            </div>
          )}
          {Object.keys(webData)?.length > 0 && (
            <div className='dashboard__website--text-box mb-sm'>
              <div className='dashboard__card-title'>
                Your website <GoBrowser className='dashboard__icon' />{" "}
              </div>
              <button className='dashboard__website--select-website'>
                <IoIosArrowDown
                  className={`dashboard__icon dashboard__icon--rotate-down ${
                    websiteToggler === true && "dashboard__icon--rotate-up"
                  }`}
                  onClick={toggleWebsiteToggler}
                />
                {websiteToggler && (
                  <div className='dashboard__website--website-switcher'>
                    <ul className='dashboard__website--website-switcher--list'>
                      {allData.websites.map((website, i) => {
                        return (
                          <li
                            onClick={codedChangeSelectedWebsite}
                            dataselect={i}
                            key={i}
                            className='dashboard__website--website-switcher--item active'
                          >
                            {website.link}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </button>
            </div>
          )}
        </div>

        <div className='dashboard__header--right pt-24 md:p-0'>
          <button onClick={codedLogOut} className='dashboard__header--btn'>
            Log out
          </button>

          <div className='dashboard__header--profile'>
            <img
              src={allData.profile_picture}
              alt='pfp'
              className='dashboard__header--icon'
            />
            {/* <CgProfile className='dashboard__header--icon' /> */}
          </div>
        </div>
      </div>

      {Object.keys(webData)?.length > 0 && (
        <div className='dashboard__layout'>
          <div className='dashboard__card dashboard__website'>
            <a
              rel='noreferrer'
              target='_blank'
              href={`https://${allData.websites[selectedWebsite].link}`}
            >
              <div className='dashboard__website--img-container dashboard__webside--relative'>
                <img
                  src={`${curData.image}`}
                  alt='nettside bilde'
                  className='dashboard__website--img dashboard__website--gradient'
                />
              </div>
            </a>
          </div>
          <div className='dashboard__card dashboard__subscription'>
            <div className='dashboard__subscription--text-box'>
              <div className='dashboard__card-title'>
                Your subscription <CiCreditCard2 className='dashboard__icon' />
              </div>
              <div className='dashboard__bold-text'>
                {!curData.payed
                  ? "Free"
                  : curData.plan === "webhotell"
                  ? "37.5 $"
                  : curData.plan === "liten"
                  ? "39.9 $"
                  : curData.plan === "medium"
                  ? "89.9 $"
                  : "149.9 $"}
              </div>
              <div className='dashboard__default-text'>
                {curData.payed
                  ? `You are currently paying ${
                      !curData.payed
                        ? "Free"
                        : curData.plan === "webhotell"
                        ? "37.5 $"
                        : curData.plan === "liten"
                        ? "39.9 $"
                        : curData.plan === "medium"
                        ? "89.9 $"
                        : "149.9 $"
                    } a month`
                  : "Change subscription to start using the site."}
              </div>
              <div className='dashboard__subscription--center'>
                <button
                  onClick={paySubcription}
                  className='dashboard__subscription--btn'
                >
                  Change subscription
                </button>
              </div>
            </div>
          </div>
          <div className='dashboard__card dashboard__users'>
            <div className='dashboard__users--text-box'>
              <div className='dashboard__card-title'>
                New users <GoPeople className='dashboard__icon' />
              </div>
              <div className='dashboard__bold-text'>
                {
                  allData.websites[selectedWebsite].analytics
                    .total_page_visitors
                }
              </div>
              <div className='dashboard__default-text'>
                {
                  allData.websites[selectedWebsite].analytics
                    .total_page_visitors
                }{" "}
                The number of new users who have visited your website this
                month.
              </div>
            </div>
          </div>
          <div className='dashboard__card dashboard__graph-1'>
            <div className='dashboard__graph-1--text-box'>
              <div className='dashboard-card-tile'>
                Visits to the website{" "}
                <BsActivity className='dashboard__icon move-down' />
              </div>

              <Line options={options} data={webData} />
            </div>
          </div>
          {/*<div className="dashboard__card">
            {curData.analytics.visitors_today} besøk idag
      </div> */}
        </div>
      )}
    </div>
  );
}

export default DashboardLayout;
